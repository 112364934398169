import React, { useState, useEffect } from "react";
import "./index.css";
import {
  getFirestore,
  collection,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import artStationImage from "./assets/artstation_bg_image.png";
import linkedInLogo from "./assets/linkedin.svg";
import dribbleLogo from "./assets/dribbble.svg";
import figmaLogo from "./assets/figma.svg";
import artstationLogo from "./assets/artstation.svg";
import profileImageBorder from "./assets/profile_bg_image.png";
import dribbleShotsImage from "./assets/dribbble_shots_image.png";

const firebaseConfig = {
  apiKey: "AIzaSyAewNbTK551UB_fBLa80YKTtQhWfN0LO0g",
  authDomain: "sample-website-a7f09.firebaseapp.com",
  projectId: "sample-website-a7f09",
  storageBucket: "sample-website-a7f09.appspot.com",
  messagingSenderId: "679269365909",
  appId: "1:679269365909:web:26a8a5d76e2989e34bc39c",
  measurementId: "G-VRSPWQWKBX",
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);

export default function App(props) {
  const fetchPost = async () => {
    await getDocs(
      query(
        collection(firestore, "work_experiences"),
        orderBy("viewOrder", "desc")
      )
    ).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setTodos(newData);
    });
  };

  const [todos, setTodos] = useState([]);

  useEffect(() => {
    fetchPost();
  }, []);

  return (
    <div className="background">
      <div className="grid">
        <div className="summary">
          <div>
            <div className="summary-title">👋 Hi, I’m Sabire</div>
            <div className="summary-description">
              an UI/UX designer with 6 years of experience.
            </div>
            <div className="summary-description">
              My design style is clean and minimal with emphasis on usability
              and ease of use. I have been involved in all aspects of a design
              project: from concept, to design, to testing, and finally
              implementation.‍
            </div>
            <div className="summary-description">
              I can say my work continues to grow with me 💕
            </div>
            <div>
              <a href="mailto:sabireavci@gmail.com">
                <button className="button">Contact with me</button>
              </a>
              <a href="https://read.cv/sabire" target="_tab">
                <button className="border-button">Read my resume</button>
              </a>
            </div>
          </div>
        </div>
        <div className="right-container">
          <img className="profile-image-border" src={profileImageBorder}></img>
        </div>
      </div>
      <div className="grid">
        <div className="timeline-container">
          {todos.map((item, index) => (
            <div key={index} className="timeline-item">
              <div className="timeline-date">{item.date}</div>
              <div className="timeline-content">
                <div className="timeline-content-title"> {item.title} </div>
                <div className="timeline-content-subtitle">
                  {" "}
                  {item.subtitle}{" "}
                </div>
                <div className="timeline-content-description">
                  {item.description}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="dribble-shots">
          <div className="artstation-image-container">
            <a href="https://dribbble.com/sabire" target="_tab">
              <img className="artstation-image" src={dribbleShotsImage} />
            </a>
          </div>
          <div>
            <a href="https://dribbble.com/sabire" target="_tab">
              <button className="border-button">See my Dribbble</button>
            </a>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="coming-soon">
          <span>Coming Soon</span>
        </div>
        <div className="dribble-shots">
          <div className="artstation-image-container">
            <a href="https://sabireavci7.artstation.com" target="_tab">
              <img className="artstation-image" src={artStationImage} />
            </a>
          </div>
          <div className="row">
            <a href="https://sabireavci7.artstation.com" target="_tab">
              <button className="border-button">See my ArtStation</button>
            </a>
            <div className="artstation-button-desc">
              Illustration is my playground side
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        <a href="https://www.linkedin.com/in/sabireavci" target="_tab">
          <img
            height={"32px"}
            width={"32px"}
            alt="linkedin"
            className="bottom-bar-item"
            src={linkedInLogo}
          ></img>
        </a>
        <a href="https://www.linkedin.com/in/sabireavci" target="_tab">
          <img
            height={"32px"}
            width={"32px"}
            alt="dribble"
            className="bottom-bar-item"
            src={dribbleLogo}
          ></img>
        </a>
        <a href="https://www.figma.com/@sabire" target="_tab">
          <img
            height={"32px"}
            width={"32px"}
            alt="figma"
            className="bottom-bar-item"
            src={figmaLogo}
          ></img>
        </a>
        <a href="https://sabireavci7.artstation.com" target="_tab">
          <img
            height={"32px"}
            width={"32px"}
            alt="artstation"
            className="bottom-bar-item"
            src={artstationLogo}
          ></img>
        </a>
      </div>
    </div>
  );
}
